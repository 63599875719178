<template>
	<div :class="themeClass">
		<!-- 列表标题 -->
		<div class="head common common-height bg-F2F4Fd flex-row-space-between-center margin-t-10">
			<div class="text-center flex-box-1">派车信息</div>
			<div class="flex-row">
				<!-- <div :class="isDetail?'width155':'width123'">派车量</div> -->
				<!-- <div v-if="isAllPage" :class="isDetail?'width155':'width123'">物流</div> -->
				<div class="flex-column-center-center" :class="isDetail?'width155':'width123'">状态</div>
				<div class="flex-column-center-center" :class="isDetail?'width155':'width123'">数量</div>
				<div class="flex-column-center-center" :class="isDetail?'width155':'width123'">操作</div>
			</div>
		</div>
		<div class="content">
			<div v-if="sendCarList.length < 1" class="flex-column-center-center">
				<svg-icon icon-class="NoContent" />
				<!-- <div>暂无内容</div> -->
			</div>
			<div class="item margin-tb-10" v-for="(item, index) in sendCarList" :key="index">
				<!-- 标题 -->
				<div class="item-title font-size14 bg-F2F4Fd common-height">
					<span class="font-weight700">{{ item.fPlateNumber }}</span>
					<span class="name">{{ item.fDriver }} {{ item.fDriverPhone }}</span>
					<span>派车单号：{{ item.fDeliveryOrderNumber }}</span>
				</div>
				<div class="item-content common flex-row-space-between padding-t-5">
					<div class="font-size14 width-fill margin-t-15">
						<!-- <div>
							<div class="goodsName">
								<i class="iconfont icon-chanpin1"></i><span>{{ item.fGoodsFullName }}</span>
							</div>
							<div class="address font-color-999">
								<i class="iconfont icon-dizhi3x"></i><span>{{ item.fDeliveryAddress }}</span>
							</div>
							<div class="engineering font-color-999">工程:&nbsp;{{ item.fProjectName }}</div>
							<div class="remarks font-color-999">备注:&nbsp;{{ item.fRemark }}</div>
							<img :src="QRCodeImg" alt="">
						</div> -->
						<div class="item-left ">
							<div class="goodsName margin-tb-10">
								<i class="iconfont icon-chanpin1 font-color-6666cc"></i><span
									class="font-size13 font-color-333">{{ item.fGoodsFullName }}</span>
							</div>
							<div class="address margin-tb-10">
								<i class="iconfont icon-dizhi3x font-color-6666cc"></i><span
									class="font-size13 font-color-333">发货仓库:&nbsp;{{item.fDepotName}}</span>
							</div>
							<!-- <img :src="QRCodeImg" alt=""> -->
						</div>
						<div class="engineering-address-box margin-tb-10">
							<span>工程:&nbsp;{{ item.fProjectName }}</span>
						</div>
            <div class="engineering-address-box margin-tb-10">
            <span >收货地址:&nbsp;{{ item.fDeliveryAddress }}</span>
            </div>
					</div>
					<div class="item-right flex-row margin-t-15">
						<div class="vehicleNum commonBox flex-colum" :class="isDetail?'width155':'width123'">
							<div class="color-theme font-size14 text-center flex-box-1" >
								<span class="font-color-FF0000">{{ item.fStatusName }}</span>
							</div>
							<div class="color-theme font-size14 text-center flex-box-1 " style="margin-top: 29px;">
								<span class="font-color-FF0000" v-if="item.fMonitorStatusName !='' && showName">{{ item.fMonitorStatusName}}</span>
							</div>
						</div>
						<!-- 						<div v-if="isAllPage" class="logistics commonBox flex-row" :class="isDetail?'width155':'width123'">
							<div class="text-center flex-box-1">
								<div class="font-color-999 margin-b-8">物流详情</div>
								<TimeLine v-model="item.timeLineData"></TimeLine>
								<div class="font-color-ff6600 margin-t-6">{{ item.fStatusName }}</div>
							</div>
						</div> -->
						<div class="vehicleNum commonBox flex-colum" :class="isDetail?'width155':'width123'">
							<div class="color-theme font-size14 text-center flex-box-1">
								<span>派车量</span>
								<span>{{ item.fScheduleVanAmount }}{{item.fUnitName}}</span>
							</div>
							<div v-if="item.fNeedAddAmount>0"
								class="color-theme font-size14 text-center flex-box-1 margin-t-10">
								<span>需补量</span>
								<span>{{ item.fNeedAddAmount  }}{{item.fUnitName}}</span>
							</div>
							<div v-if="item.fDeliveryAmount>0"
								class="color-theme font-size14 text-center flex-box-1 margin-t-10">
								<span>提货量</span>
								<span>{{item.fDeliveryAmount}}{{item.fUnitName}}</span>
							</div>
						</div>
						<div class="operation commonBox flex-row font-size13 width123">
							<div class="flex-box-1">
								<!-- 提货码 是 只要有派车单就会生成 -->
								<el-popover v-if="item.fEnableSendPassWordStatus ==1" placement="right" width="233"
									trigger="click" @show="popoverShow(index,item)" @hide="popoverHide">
									<div class="delivery-code-box flex-colum-space-between">
										<!--  v-show="isCreateQRCode" -->
										<div style="position: relative;" :id="'captureId'+index"
											class="delivery-code-item flex-colum-space-between">
											<div style="position: absolute;top:6px" class="font-size16 font-weight700">
												提货密码：{{ qrCoderContext }}</div>
											<!-- <div :id="'qrCodeDivID'+index" :ref="'qrCodeDiv'+index" class="QR-code"></div>
											<div class="font-color-666 font-size13">
												提货信息：
												<div ref="copyDiv" class="QRcode-text">{{ qrCoderText }}</div>
											</div> -->
											<img :src="imageUrl" />
										</div>
										<div @click="copyInfo(index)" class="copy-btn flex-row-center-center pointer">
											复制提货信息
										</div>
										<!-- <el-button style="width: 202px;margin-top: 10px;" plain :loading="true" size="medium">下载图片</el-button> -->
									</div>
									<div slot="reference"
										class="pointer operation-btn background-color-theme background-color-theme text-center">
										提货码
									</div>
								</el-popover>
								<div @click="goIncrease(item)" v-if="item.fEnableAddAmountStatus == 1"
									class="pointer operation-btn background-color-theme background-color-theme text-center">
									派车补量
								</div>
								<div @click="deleteOrder(item)" v-if="item.fEnableDeleteStatus == 1"
									class="pointer operation-btn border-color-theme color-theme text-center">
									作废
								</div>
								<div @click="signForCustomer(item)" v-if="item.fEnableCustomerSignStatus == 1"
									class="pointer operation-btn background-color-theme background-color-theme text-center">
									客户签收
								</div>
								<div @click="signForCarrier(item)"
									v-if="item.fEnableCarrierSignStatus == 1&&item.fDeliveryID!=2"
									class="pointer operation-btn background-color-theme background-color-theme text-center">
									承运商签收
								</div>
								<!-- 								<div @click="sendCar(item)" v-if="item.fEnableSendPassWordStatus == 1" class="pointer operation-btn border-color-theme color-theme text-center">
									发送
								</div> -->
								<div @click="goDetail(item)"
									class="pointer operation-btn border-color-theme color-theme text-center">
									详情
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="order-number-box flex-row-space-between-center font-size13 font-color-999  margin-tb-10">
					<div>
						订单号: {{item.fOrderNumber}}
					</div>
					<div class="flex-row-align-center">
						<div v-if="item.fStatus == 1 || item.fStatus == 2">派车时间:
							{{ item.fCreateTime?item.fCreateTime:'--'}}
						</div>
						<div v-if="item.fStatus == 3">进厂时间: {{ item.fTareTime?item.fTareTime:'--'}}</div>
						<div v-if="item.fStatus >= 4" class="margin-l-10">出厂时间: {{item.fGrossTime?item.fGrossTime:'--'}}
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import SERVER from '../../../config/config.json'
	import TimeLine from './TimeLine.vue';
	import html2canvas from 'html2canvas'
	import QRCode from 'qrcodejs2'
	export default {
		name: "sendCarList",
		components: {
			TimeLine
		},
		props: {
			value: {
				type: Array,
				default: () => []
			},
			isAllPage: {
				type: Boolean,
				default: false
			},
			isDetail: {
				type: Boolean,
				default: false
			},
			showName:{
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				sendCarList: this.value,
				QRCodeImg: '',
				qrCoderContext: "",
				qrCoderText: "",
				imageUrl: ''
			};
		},
		watch: {
			async value(newValue, oldValue) {
				this.sendCarList = await newValue;
			}
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {},
		methods: {
			// createQrCodeByList() {
			// 	this.sendCarList.forEach((item, index) => {
			// 		this.createQrCode(index)
			// 	})
			// },
			// 提货码显示
			popoverShow(listIndex, item) {
				console.log('SERVER', SERVER);
				let url = SERVER.Environment == 'test' ? SERVER.API_SERVER : window.API_SERVER;
				this.imageUrl = url +
					'api/mall/ebsale/deliver-order/get-d-code-png/' + item.fDeliveryOrderID +
					'.png'
				this.getQRCodeByDeliveryList(listIndex, item);
			},
			// 根据派车单ID 获取提货码信息
			getQRCodeByDeliveryList(listIndex, item) {
				this.ApiRequestPostNOMess('api/mall/ebsale/deliver-order/get-qr-code-text', {
					id: item.fDeliveryOrderID
				}).then(res => {
					this.qrCoderContext = res.obj.password
					// this.qrCoderText= res.obj.text

					this.qrCoderText = res.obj.text.replace(/<br>/gi, ""); //清除"数字"和"."以外的字符
					// this.createQrCode(listIndex, res.obj.password)
				}, error => {})
			},
			// 生成二维码
			createQrCode(listIndex, qrCoderContext) {
				let refQRID = 'qrCodeDivID' + listIndex
				let RQDpcument = document.getElementById(refQRID)
				RQDpcument.innerHTML = ""
				this.QRCode = new QRCode(RQDpcument, {
					width: 140,
					height: 140,
					text: qrCoderContext, // 二维码地址 TODO 
					colorDark: "#000", // 二维码颜色
					colorLight: "#fff" // 二维码背景色
				});
			},
			popoverHide() {
				// this.QRCode.clear()
			},
			// 生成涵盖二维码和文字的图片
			createQRCodeImg(listIndex, item) {
				// 根据列表下标生成ID
				let refQRBox = '#captureId' + listIndex
				// 根据ID获取元素信息
				let documentData = document.querySelector(refQRBox)
				// 调用方法将dom信息通过画布画出来
				html2canvas(documentData)
					.then(canvas => {
						// 将画布画好的数据转为图片
						let imgData = canvas.toDataURL("image/jpeg");
						// this.QRCodeImg = imgData;
						let event = new MouseEvent("click"); // 创建一个单击事件
						let a = document.createElement("a"); // 生成一个a元素
						a.href = imgData; // 将生成的URL设置为a.href属性
						a.download = "photo.jpg"; // 设置图片名称
						a.dispatchEvent(event); // 触发a的单击事件
					})
					.catch(error => {});
			},
			// 复制提货信息
			copyInfo(listIndex) {
				let refCopyInfo = this.qrCoderText;
				var input = document.createElement("input"); // js创建一个input输入框
				input.value = refCopyInfo; // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
				document.body.appendChild(input); // 将输入框暂时创建到实例里面
				input.select(); // 选中输入框中的内容
				document.execCommand("Copy"); // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				this.tipsInfo("复制提货信息成功")
			},
			// 签收  客户  确定验证
			signForCustomer(item) {
				// fDeliveryOrderID  派车订单ID
				this.$confirm('确定要签收此订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.signForCustomerFun(item);
				}).catch(() => {

				});
			},
			// 签收  客户
			signForCustomerFun(item) {
				this.ApiRequestPost("api/mall/ebsale/deliver-order/current-customer-sign", {
					fDeliveryOrderID: item.fDeliveryOrderID.toString(),
					frv: item.frv
				}).then(res => {
					this.$emit("queryList")
				}).catch(err => {
					this.$emit("queryList")
				})
			},
			// 签收 承运商  确定验证
			signForCarrier(item) {
				// fDeliveryOrderID  派车订单ID
				this.$confirm('确定要签收此订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.signForCarrierFun(item);
				}).catch(() => {

				});
			},
			// 签收 承运商  暂无接口
			signForCarrierFun(item) {
				this.ApiRequestPost("api/mall/ebsale/deliver-order/carrier-sign", {
					fDeliveryOrderID: item.fDeliveryOrderID.toString(),
					frv: item.frv
				}).then(res => {
					this.$emit("queryList")
				}).catch(err => {
					this.$emit("queryList")
				})
			},
			// 作废
			deleteOrder(item) {
				// fDeliveryOrderID  派车订单ID
				this.$confirm('确定要作废此派车单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							setTimeout(() => {
								done();
								setTimeout(() => {
									instance.confirmButtonLoading = false;
								}, 300);
							}, 1000);
						} else {
							done();
						}
					}
				}).then(() => {
					this.ApiRequestPost("api/mall/ebsale/deliver-order/is-delete", {
						id: item.fDeliveryOrderID.toString()
					}).then(res => {
						this.$emit("queryList")
					}).catch(err => {
						this.$emit("queryList")
					})
				}).catch(() => {});
			},
			goDetail(item) {
				// fDeliveryOrderID  派车订单ID
				this.$store.commit("changeCommonData", {
					id: item.fDeliveryOrderID
				});
				this.$router.push({
					name: "SendCarDetail",
					query: {
						id: item.fDeliveryOrderID,
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title,
						fEnableSendPassWordStatus: item.fEnableSendPassWordStatus
					}
				});
			},
			// 派车补量
			goIncrease(item) {
				// fDeliveryOrderID  派车订单ID
				this.$store.commit("changeCommonData", {
					id: item.fDeliveryOrderID
				});
				this.$router.push({
					name: "VehicleDispatchingIncrease"
				});
			},
			sendCar(item) {
				// 调用方法
				this.tipsInfo("需要发送接口")
			}
		}
	};
</script>

<style lang="scss" scoped>
	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.QRcode-text {
		white-space: pre-line;
	}

	// 提货码 包裹盒子
	.delivery-code-box {
		width: 100%;
		min-height: 328px;
		padding-top: 8px;
	}

	.delivery-code-item {
		margin: 10px 0;
		width: 202px;
		min-height: 270px;
	}

	.QR-code {
		// width: 140px;
		// height: 140px;
		// background-color: #000;
	}

	.copy-btn {
		width: 200px;
		height: 34px;
		border: 1px solid #0173FE;
		color: #0173FE;
	}

	.width123 {
		width: 123px;
	}

	.width155 {
		width: 155px;
	}

	.margin-b-8 {
		margin-bottom: 8px;
	}

	.margin-t-3 {
		margin-top: 3px;
	}

	.font-color-ff6600 {
		color: #ff6600;
	}

	.common-height {
		height: 35px;
		line-height: 35px;
	}

	.common {
		width: 100%;

		// div {
		// 	&:nth-child(1) {
		// 		flex: 1;
		// 	}
		// }
	}

	.head {
		width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 20px;
		background-color: #fff;
    border-radius: 10px;
	}

	.content {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    background: #fff;
    border-radius: 10px;
		// margin: 10px 0;

		.item {
      border-radius: 10px;
			.item-title {
        padding-left: 17px;
        // width: 100%;
				background: #D2E7FF;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        color: #0173FE;


				.name {
					margin-left: 15px;
					margin-right: 20px;
				}
			}

			.item-content {
				border: 1px solid #f6f8fe;
				box-sizing: border-box;
        border-radius: 10px;

				.item-left {
					padding-left: 17px;

					.goodsName {
						height: 24px;
						line-height: 24px;
					}

					.address {
						height: 24px;
						line-height: 24px;
					}

					.engineering {
						height: 16px;
						line-height: 16px;
						margin-left: 21px;
						margin-top: 8px;
					}


					.remarks {
						height: 16px;
						line-height: 16px;
						margin-left: 21px;
						margin-top: 7px;
					}

					div {
						width: 100%;
					}

					span {
						margin-left: 7px;
					}
				}

				.item-right {
					.commonBox {
						box-sizing: border-box;
						border-left: 1px solid #f2f4fd;
					}

					.logistics {
						.timeline {
							li {
								&:nth-child(2n + 1) {
									width: 10px;
									height: 10px;
									background: #ff6600;
									border-radius: 10px;
								}

								&:nth-child(2n) {
									width: 24px;
									height: 0px;
									border: 1px dotted #ff6600;
								}

								&:nth-child(n + 4) {
									border: 1px dotted #ccc;
								}

								&:nth-child(n + 5) {
									background: #ccc;
								}
							}
						}
					}

					.operation {
						.operation-btn {
							width: 75px;
							height: 24px;
							line-height: 24px;
							border-radius: 2px;
							box-sizing: border-box;
							margin: 0 auto;

							&:nth-child(n + 2) {
								margin-top: 4px;
							}
						}
					}
				}
			}
		}
	}

	.engineering-address-box {
		// line-height: 38px;
		// background-color: #ededfa;
		padding-left: 17px;
		color: #6666cc;
	}

	.flex-box-1 {
		flex: 1;
	}

	.order-number-box {
		height: 36px;
		border: 1px solid #f6f8fe;
		border-top: 0;
		padding: 0 17px 0 17px;
    padding-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
	}
</style>
