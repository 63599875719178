<!-- 
引入组件：import pagination from '@/components/pagination/pagination.vue'
注册组件：
components: {
	pagination
},

组件使用：<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
				 :pagesize="pagesize" :total="total">
				</pagination>
@handleSizeChange:pageSize（每页个数） 改变时会触发
handleSizeChange(val) {
	console.log(`每页 ${val} 条`);
},

@handleCurrentChange://currentPage（当前页数） 改变时会触发
handleCurrentChange(val) {
	console.log(`当前页: ${val}`);
}

total:  //总条数
pagesize:  //每页显示条目个数
currentPage:  //当前页
 -->
<template>
	<div :class="themeClass">
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="current"
		 :page-size="pagesize" layout="prev, pager, next, jumper" :total="total" prev-text="上一页" next-text="下一页" :pager-count="5">
		</el-pagination>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		props: {
			currentPage: {
				type: Number,
				default: 0
			},
			pagesize: {
				type: Number,
				default: 0
			},
			total: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {

			}
		},
		computed: {
			current: {
				get() {
					return this.currentPage
				},
				set() {}
			},
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			document.getElementsByClassName('el-pagination__jump')[0].childNodes[0].nodeValue = '跳至'
		},
		methods: {
			handleSizeChange(val) {
				this.$emit('handleSizeChange', val)
			},
			handleCurrentChange(val) {
				this.$emit('handleCurrentChange', val)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.el-pagination::v-deep {

		li,
		.btn-prev {
			border: 1px solid #F2F2F2 !important;
			margin-right: 5px;
			border-radius: 5px;
		}

		li:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.btn-prev:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.btn-next:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.el-input__inner{
			border: 1px solid #F2F2F2 !important;
		}

		.btn-next {
			border: 1px solid #F2F2F2 !important;
			border-radius: 5px;
		}

		.active {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}
	}
</style>
