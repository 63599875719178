<template>
	<div :class="themeClass">
		<!-- 列表标题 -->
		<div class="head common common-height bg-F2F4Fd flex-row-space-between-center margin-t-10">
			<div class="text-center">派车信息</div>
			<div class="flex-row">
				<div class="flex-column-center-center" :class="isDetail?'width155':'width123'">状态</div>
				<div class="flex-column-center-center" :class="isDetail?'width155':'width123'">数量</div>
				<div class="flex-column-center-center" :class="isDetail?'width155':'width123'">操作</div>
			</div>
		</div>
		<div class="content">
			<div v-if="value.length < 1" class="flex-column-center-center">
				<svg-icon icon-class="NoContent" />
				<!-- <div>暂无内容</div> -->
			</div>
			<div class="item margin-tb-10" v-for="(item, index) in value" :key="index">
				<!-- 标题 -->
				<div class="item-title font-size14 bg-F2F4Fd common-height">
					<span class="carID color-theme font-weight700">{{ item.deliverOrder.fPlateNumber }}</span>
					<span class="name">{{ item.deliverOrder.fConsignee }} {{ item.deliverOrder.fConsigneePhone }}</span>
					<span>派车单号：{{ item.deliverOrder.fDeliveryOrderNumber }}</span>
					<span class="name">补量单号：{{ item.deliverOrder.fOrderNumber }}</span>
				</div>
				<div class="item-content common flex-row-space-between padding-t-5">
					<div class="font-size14 width-fill">
						<div class="item-left">
							<div class="goodsName">
								<i class="iconfont icon-chanpin1 font-color-6666cc"></i><span class="font-size13 font-color-333">{{ item.deliverOrder.fGoodsFullName }}</span>
							</div>
							<div class="address">
								<i class="iconfont icon-dizhi3x font-color-6666cc"></i><span class="font-size13 font-color-333">发货仓库:&nbsp;{{item.deliverOrder.fDepotName}}</span>
							</div>
						</div>
						<div class="engineering-address-box">
							<span>工程:&nbsp;{{ item.deliverOrder.fProjectName }}</span>
							<span class="margin-l-10">收货地址:&nbsp;{{ item.deliverOrder.fDeliveryAddress }}</span>
						</div>
					</div>
					<div class="item-right flex-row">
						<div class="vehicleNum commonBox flex-row" :class="isDetail?'width155':'width123'">
							<div class="color-theme font-size14 text-center flex-box-1">
								<span class="font-color-FF0000">{{ item.fPlyingStatusName }}</span>
							</div>
						</div>
						<div class="vehicleNum commonBox flex-row" :class="isDetail?'width155':'width123'">
							<div class="color-theme font-size14">
								<span>派车量</span><br />
								<span>{{ item.fScheduleVanAmount }}吨</span><br />
								<span>派车补量</span><br />
								<span>{{ item.fAddAmount }}吨</span>
							</div>
						</div>
						<div class="operation commonBox flex-row font-size13 width123">
							<div>
								<div @click="payOrder(item)" v-if="item.fEnablePlyStatus == 1" class="pointer operation-btn background-color-theme background-color-theme">
									去支付
								</div>
								<div @click="deleteOrder(item)" v-if="item.fEnableDeleteStatus == 1" class="pointer operation-btn border-color-theme color-theme text-center">
									作废
								</div>
								<div @click="goDetail(item)" class="pointer operation-btn border-color-theme color-theme">
									详情
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="order-number-box flex-row-space-between-center font-size13 font-color-999">
					<div>
						订单号: {{item.fOrderNumber}}
					</div>
					<div class="flex-row-align-center">
						<div>派车时间: {{ item.deliverOrder.fCreateTime?item.deliverOrder.fCreateTime:'--'}}</div>
						<div class="margin-l-10">出厂时间: {{item.deliverOrder.fGrossTime?item.deliverOrder.fGrossTime:'--'}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "paySendCar",
		props: {
			value: {
				type: Array,
				default: () => []
			},
			isDetail: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {

			};
		},
		computed: {
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {},
		methods: {
			goDetail(item) {
				this.$store.commit("changeCommonData", {
					id: item.fDeliveryOrderID
				});
				this.$router.push({
					name: "SendCarDetail",
					query: {
						id: item.fDeliveryOrderID,
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title
					}
				});
			},
			// 作废
			deleteOrder(item) {
				// console.log(item);
				this.$confirm('确定要作废此补量单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							setTimeout(() => {
								done();
								setTimeout(() => {
									instance.confirmButtonLoading = false;
								}, 300);
							}, 1000);
						} else {
							done();
						}
					}
				}).then(() => {
					this.ApiRequestPost("/api/mall/ebsale/order-add-record/to-delete", [{
						fOrderAddRecordID: item.fOrderAddRecordID,
						frv: item.frv,
						rowStatus: this.rowStatus_delete
					}]).then(res => {
						this.$emit("queryList")
					}).catch(err => {
						this.$emit("queryList")
					})
				}).catch(() => {});
			},
			payOrder(item) {
				if (this.getUserInfo.fShowPrice == 0) {
					this.$message('当前用户不能进行派车补量单支付');
					return;
				}
				let orderIds = [
					item.fOrderAddRecordID
				]
				// fDeliveryOrderID  派车订单ID
				this.$confirm('确定要支付此订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.replace({
						name: "PaymentOrder",
						params: {
							orderIds: orderIds,
							fBillTypeID: item.fBillTypeID
						}
					})
				}).catch(() => {

				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.width123 {
		width: 123px;
	}

	.width155 {
		width: 155px;
	}

	.margin-b-8 {
		margin-bottom: 8px;
	}

	.margin-t-3 {
		margin-top: 3px;
	}

	.font-color-ff6600 {
		color: #ff6600;
	}

	.common-height {
		height: 35px;
		line-height: 35px;
	}

	.common {
		width: 100%;

		div {
			&:nth-child(1) {
				flex: 1;
			}

			&:nth-child(2) {
				div {
					flex: 1;
					text-align: center;
				}
			}
		}
	}

	.head {
		width: 100%;
		background-color: #f2f4fd;
	}

	.content {
		margin: 10px 0;

		.item {
			.item-title {
				background: #f2f4fd;

				.carID {
					margin-left: 17px;
				}

				.name {
					margin-left: 15px;
					margin-right: 20px;
				}
			}

			.item-content {
				border: 1px solid #f6f8fe;
				box-sizing: border-box;

				.item-left {
					padding-left: 17px;

					.goodsName {
						height: 24px;
						line-height: 24px;
					}

					.address {
						height: 18px;
						line-height: 18px;
					}

					.engineering {
						height: 16px;
						line-height: 16px;
						margin-left: 21px;
						margin-top: 8px;
					}

					.remarks {
						height: 16px;
						line-height: 16px;
						margin-left: 21px;
						margin-top: 7px;
					}

					div {
						width: 100%;
					}

					span {
						margin-left: 7px;
					}
				}

				.item-right {
					.commonBox {
						box-sizing: border-box;
						border-left: 1px solid #f2f4fd;
					}

					.logistics {
						.timeline {
							li {
								&:nth-child(2n + 1) {
									width: 10px;
									height: 10px;
									background: #ff6600;
									border-radius: 10px;
								}

								&:nth-child(2n) {
									width: 24px;
									height: 0px;
									border: 1px dotted #ff6600;
								}

								&:nth-child(n + 4) {
									border: 1px dotted #ccc;
								}

								&:nth-child(n + 5) {
									background: #ccc;
								}
							}
						}
					}

					.operation {
						.operation-btn {
							width: 75px;
							height: 24px;
							line-height: 24px;
							border-radius: 2px;
							box-sizing: border-box;
							margin: 0 auto;

							&:nth-child(n + 2) {
								margin-top: 4px;
							}
						}
					}
				}
			}
		}
	}

	.flex-box-1 {
		flex: 1;
	}

	.engineering-address-box {
		padding-left: 17px;
		color: #6666cc;
	}

	.order-number-box {
		height: 36px;
		border: 1px solid #f6f8fe;
		border-top: 0;
		padding: 0 17px 0 17px;
	}
</style>
